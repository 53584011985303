export const SENIOR_AGE = 60
export const DAY_TIME_MILLISEC = 24 * 60 * 60 * 1000

export const PLATFORM_TARRAKKI = 'TARRAKKI'
export const PLATFORM_SETU = 'SETU'
export const PLATFORM_UPSWING = 'UPSWING'
export const PLATFORM_FINCARE = 'FINCARE'
export const PLATFORM_AXIS = 'AXIS'

export const ISSUER_FIRSTNAME_BAJAJ = 'Bajaj'
export const ISSUER_FIRSTNAME_SHRIRAM = 'Shriram'
export const ISSUER_FIRSTNAME_MAHINDRA = 'Mahindra'
export const ISSUER_FIRSTNAME_UNITY = 'Unity'

export const SBI_BANK = 'sbi'
export const HDFC_BANK = 'hdfc'
export const ICICI_BANK = 'icici'
export const AXIS_BANK = 'axis'
export const KOTAK_BANK = 'kotak'
export const INDUSIND_BANK = 'indusind'
export const BANK_OF_BARODA_BANK = 'bank_of_baroda'
export const CANARA_BANK = 'canara'
export const PNB_BANK = 'pnb'
export const IDBI_BANK = 'idbi'

export type Platform =
	| typeof PLATFORM_TARRAKKI
	| typeof PLATFORM_SETU
	| typeof PLATFORM_UPSWING
	| typeof PLATFORM_FINCARE
	| typeof PLATFORM_AXIS

export const TARRAKKI_STATUS_NEW = 'new'
export const TARRAKKI_STATUS_AWAITING_PAYMENT = 'awaiting_payment'
export const TARRAKKI_STATUS_AWAITING_DOCUMENTS = 'awaiting_documents'
export const TARRAKKI_STATUS_UNDER_REVIEW = 'under_review'
export const TARRAKKI_STATUS_ACTIVE = 'active'
export const TARRAKKI_STATUS_MATURED = 'matured'
export const TARRAKKI_STATUS_REJECTED = 'rejected'

const IMAGE_BASE_URL = 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/bank-logos'

const SBI_BANK_NAME = 'State Bank of India'
const ICICI_BANK_NAME = 'ICICI Bank'
const UNITY_SF_BANK = 'Unity SF Bank'
const SURYODAY_SF_BANK = 'Suryoday SF Bank'
const SHRIRAM_FINANCE_NAME = 'Shriram Finance'
const BANDHAN_BANK_NAME = 'Bandhan Bank'
const INDUSIND_BANK_NAME = 'IndusInd Bank'
const BANK_OF_INDIA_NAME = 'Bank of India'
const UTKARSH_SF_BANK_NAME = 'Utkarsh SF Bank'
const AXIS_BANK_NAME = 'Axis Bank'
const CANARA_BANK_NAME = 'Canara Bank'
const BANK_OF_BARODA_NAME = 'Bank of Baroda'
const NORTHEAST_SF_BANK_NAME = 'North East SF Bank'
const SHIVALIK_SF_BANK_NAME = 'Shivalik SF Bank'
const HDFC_BANK_NAME = 'HDFC Bank'
const KOTAK_BANK_NAME = 'Kotak Bank'
const DCB_BANK_NAME = 'DCB Bank'
const RBL_BANK_NAME = 'RBL Bank'
const AU_SF_BANK_NAME = 'AU SF Bank'
const PNB_BANK_NAME = 'PNB Bank'
const IDBI_BANK_NAME = 'IDBI Bank'
const UJJIVAN_SF_BANK_NAME = 'Ujjivan SF Bank'
const BAJAJ_FINANCE_NAME = 'Bajaj Finance'
const FINCARE_SF_BANK_NAME = 'Fincare SF Bank'
const JANA_SF_BANK_NAME = 'Jana SF Bank'
const SBM_BANK_NAME = 'SBM Bank'
const MAHINDRA_FINANCE_NAME = 'Mahindra Finance'

export const AllBanksName = [
  ICICI_BANK_NAME,
  HDFC_BANK_NAME,
  AXIS_BANK_NAME,
  BANK_OF_INDIA_NAME,
  CANARA_BANK_NAME,
  BANK_OF_BARODA_NAME,
  PNB_BANK_NAME,
  BANDHAN_BANK_NAME,
  INDUSIND_BANK_NAME,
  KOTAK_BANK_NAME,
  DCB_BANK_NAME,
  RBL_BANK_NAME,
  IDBI_BANK_NAME,
  SBM_BANK_NAME,
  
  UNITY_SF_BANK,
  NORTHEAST_SF_BANK_NAME,
  SURYODAY_SF_BANK,
  UTKARSH_SF_BANK_NAME,
  SHIVALIK_SF_BANK_NAME,
  AU_SF_BANK_NAME,
  UJJIVAN_SF_BANK_NAME,
  FINCARE_SF_BANK_NAME,
  JANA_SF_BANK_NAME,

  SHRIRAM_FINANCE_NAME,
  BAJAJ_FINANCE_NAME,
  MAHINDRA_FINANCE_NAME
]

export type BanksName = typeof SBI_BANK_NAME 
| typeof ICICI_BANK_NAME
| typeof HDFC_BANK_NAME
| typeof AXIS_BANK_NAME
| typeof BANK_OF_INDIA_NAME
| typeof CANARA_BANK_NAME
| typeof BANK_OF_BARODA_NAME
| typeof PNB_BANK_NAME
| typeof BANDHAN_BANK_NAME
| typeof INDUSIND_BANK_NAME
| typeof KOTAK_BANK_NAME
| typeof DCB_BANK_NAME
| typeof RBL_BANK_NAME
| typeof IDBI_BANK_NAME
| typeof SBM_BANK_NAME
| typeof UNITY_SF_BANK
| typeof NORTHEAST_SF_BANK_NAME
| typeof SURYODAY_SF_BANK
| typeof UTKARSH_SF_BANK_NAME
| typeof SHIVALIK_SF_BANK_NAME
| typeof AU_SF_BANK_NAME
| typeof UJJIVAN_SF_BANK_NAME
| typeof FINCARE_SF_BANK_NAME
| typeof JANA_SF_BANK_NAME
| typeof SHRIRAM_FINANCE_NAME
| typeof BAJAJ_FINANCE_NAME
| typeof MAHINDRA_FINANCE_NAME

export function getBankLogo(fdName: BanksName){
    switch(fdName){
        case SBI_BANK_NAME: return `${IMAGE_BASE_URL}/sbi.png`
        case SURYODAY_SF_BANK: return `${IMAGE_BASE_URL}/Suryoday.webp`
        case SHRIRAM_FINANCE_NAME: return `${IMAGE_BASE_URL}/shriram.jpeg`
        case UNITY_SF_BANK: return `${IMAGE_BASE_URL}/unity.png`
        case ICICI_BANK_NAME: return `${IMAGE_BASE_URL}/icici.png`

		case HDFC_BANK_NAME: return `${IMAGE_BASE_URL}/hdfc.png`
        case AXIS_BANK_NAME: return `${IMAGE_BASE_URL}/axis_new.png`
        case BANK_OF_BARODA_NAME: return `${IMAGE_BASE_URL}/baroda.png`
        case CANARA_BANK_NAME: return `${IMAGE_BASE_URL}/CanaraBank.webp`
        case BANK_OF_INDIA_NAME: return `${IMAGE_BASE_URL}/boi.png`

		case PNB_BANK_NAME: return `${IMAGE_BASE_URL}/pnb.png`
        case BANDHAN_BANK_NAME: return `${IMAGE_BASE_URL}/bandhan.webp`
        case INDUSIND_BANK_NAME: return `${IMAGE_BASE_URL}/indusind.webp`
        case KOTAK_BANK_NAME: return `${IMAGE_BASE_URL}/kotak.png`
        case DCB_BANK_NAME: return `${IMAGE_BASE_URL}/dcb.webp`

		case RBL_BANK_NAME: return `${IMAGE_BASE_URL}/rbl.webp`
        case IDBI_BANK_NAME: return `${IMAGE_BASE_URL}/IDBI.jpg`
        case SBM_BANK_NAME: return `${IMAGE_BASE_URL}/sbm.webp`

        case NORTHEAST_SF_BANK_NAME: return `${IMAGE_BASE_URL}/northeast.webp`
        case UTKARSH_SF_BANK_NAME: return `${IMAGE_BASE_URL}/utkarsh.png`
		case SHIVALIK_SF_BANK_NAME: return `${IMAGE_BASE_URL}/shivalik.webp`
        case AU_SF_BANK_NAME: return `${IMAGE_BASE_URL}/au.webp`
        case UJJIVAN_SF_BANK_NAME: return `${IMAGE_BASE_URL}/Ujjivan.webp`
        case FINCARE_SF_BANK_NAME: return `${IMAGE_BASE_URL}/fincare.png`
        case JANA_SF_BANK_NAME: return `${IMAGE_BASE_URL}/jana.webp`

        case BAJAJ_FINANCE_NAME: return `${IMAGE_BASE_URL}/bajaj.jpg`
        case MAHINDRA_FINANCE_NAME: return `${IMAGE_BASE_URL}/mahindra.jpg`

        default: return `${IMAGE_BASE_URL}/superfd.png`
    }
}