import { type ReactNode } from 'react'
import Tilt from 'react-parallax-tilt'
import { cn } from '#app/utils/misc.tsx'

export default function TiltCard({children, className}: { children: ReactNode, className?: string}){
    return (
      <Tilt
       tiltMaxAngleX={5}
       tiltMaxAngleY={10}
       glareEnable
       tiltAngleXInitial={0}
       glareMaxOpacity={0.1}
       className={cn("fix-safari-tilt relative overflow-hidden rounded-xl bg-gradient-to-b from-zinc-200 to-white p-px dark:from-white dark:via-zinc-200 dark:to-white", className)}
      >
        <div className="relative box-border p-3 flex h-full justify-center items-center gap-6 rounded-xl border-1 border-neutral-200 ">
         {children}
        </div>
      </Tilt>
    )
}