import { cva, type VariantProps } from "class-variance-authority"
import * as React from "react"

import { cn } from '#app/utils/misc.tsx'

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
        fd_pending: "px-3 py-1.5 border-transparent bg-yellow-100 text-yellow-900 shadow hover:bg-yellow/80",
        fd_ongoing: "px-3 py-1.5 border-transparent bg-green-100 text-green-900 shadow hover:bg-yellow/80",
        fd_matured: "px-3 py-1.5 border-transparent bg-violet-100 text-violet-900 shadow hover:bg-violet/80",
        max_return: 
          "px-1 py-0.2 border-transparent bg-green-500 text-badge-xxs text-primary-foreground shadow hover:bg-primary/80",
        most_popular: 
          "px-1 py-0.2 border-transparent bg-blue-500 text-badge-xxs text-primary-foreground shadow hover:bg-primary/80 rounded-l-md",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const OBadgeVariants = cva(
  "inline-flex items-center justify-center rounded-full border px-1.5 text-xs font-medium leading-normal transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground",
        secondary: "border-transparent bg-secondary text-secondary-foreground",
        destructive: "border-transparent bg-destructive text-destructive-foreground",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export interface OBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof OBadgeVariants> {}

function OBadge({ className, variant, ...props }: OBadgeProps) {
  return (
    <div className={cn(OBadgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, OBadge, OBadgeVariants, badgeVariants }
